import "./assets/css/App.css";
import Routes from "./routes/routes.js";
import Header from "./layouts/Header";

function App() {
  return (
    <>
      <Header />
      <Routes />
    </>
  );
}

export default App;
